import { Injectable } from '@angular/core';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';
import { BehaviorSubject } from "rxjs";
import { Countries } from './basic-setting-models/countries';

@Injectable({
  providedIn: 'root'
})
export class BasicSettingService {

  apiUrl = environment.basePath;
  public fetchCountries = new BehaviorSubject<Array<Countries>>(null);
  public countries = this.fetchCountries.asObservable();
  private windowScrollEvent$ = new BehaviorSubject(null);
  public tapWindowScrollEvent = this.windowScrollEvent$.asObservable();
  pageSize: number = 30;

  constructor(
    private baseService: BaseService
  ) { }


  windowScrolled(event: any) {
    this.windowScrollEvent$.next(event);
  }
  addSkill(data) {
    return this.baseService.post(this.apiUrl + '/skills', true, data);
  }

  getSkills(pageNo, srchString) {
    return this.baseService.get(this.apiUrl + `/skills?pageNumber=${pageNo}&pageSize=${this.pageSize}&skillName=${srchString}`, true);
  }

  updateSkill(data) {
    return this.baseService.patch(this.apiUrl + `/skills`, true, data);
  }

  deleteSkill(id) {
    return this.baseService.delete(this.apiUrl + `/skills/${id}`, true);
  }

  addEducation(data) {
    return this.baseService.post(this.apiUrl + '/educations/', true, data);
  }

  getEducations(pageNo, srchString) {
    return this.baseService.get(this.apiUrl + `/educations?educationName=${srchString}&pageNumber=${pageNo}&pageSize=${this.pageSize}`, true);
  }

  updateEducation(data) {
    return this.baseService.patch(this.apiUrl + `/educations`, true, data);
  }

  deleteEducation(id) {
    return this.baseService.delete(this.apiUrl + `/educations/${id}`, true);
  }

  getCountries() {
    return this.baseService.get(this.apiUrl + `/countries`, true);
  }

  getStatesByCountryId(id) {
    return this.baseService.get(this.apiUrl + `/states/${id}`, true);
  }

  addLicence(data) {
    return this.baseService.post(this.apiUrl + '/licenses/', true, data);
  }

  getLicences(pageNo: number, data: any) {
    return this.baseService.post(this.apiUrl + `/licenses/_search?pageNumber=${pageNo}&pageSize=${this.pageSize}`, true, data);
  }

  deleteLicence(id) {
    return this.baseService.delete(this.apiUrl + `/licenses/${id}`, true);
  }

  updateLicence(data) {
    return this.baseService.patch(this.apiUrl + `/licenses/`, true, data);
  }

  addUnion(data) {
    return this.baseService.post(this.apiUrl + '/unions', true, data);
  }

  getUnions(pageNo: number, data: any) {
    return this.baseService.post(this.apiUrl + `/unions/_search?pageNumber=${pageNo}&pageSize=${this.pageSize}`, true, data);
  }

  deleteUnion(id) {
    return this.baseService.delete(this.apiUrl + `/unions/${id}`, true);
  }

  updateUnion(data) {
    return this.baseService.patch(this.apiUrl + `/unions`, true, data);
  }

  checkUserInUnion(unionid) {
    return this.baseService.get(this.apiUrl + `/unions/employees/${unionid}`, true);
  }

  checkUserInEducation(eduid) {
    return this.baseService.get(this.apiUrl + `/educations/employees/${eduid}`, true);
  }

  checkUserInLnC(licid) {
    return this.baseService.get(this.apiUrl + `/licenses/employees/${licid}`, true);
  }

  checkUserInSkill(skillid) {
    return this.baseService.get(this.apiUrl + `/skills/employees/${skillid}`, true);
  }

  //groups or roles
  getGroups(pageNo, srchString) {
    return this.baseService.get(this.apiUrl + `/userRoles?pageNumber=${pageNo}&pageSize=${this.pageSize}&roleName=${srchString}`, true);
  }
  addGroup(data) {
    return this.baseService.post(this.apiUrl + '/userRoles', true, data);
  }

  updateGroup(data) {
    return this.baseService.patch(this.apiUrl + `/userRoles`, true, data);
  }

  deleteGroup(id) {
    return this.baseService.delete(this.apiUrl + `/userRoles/${id}`, true);
  }
}
