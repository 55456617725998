import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { DynamicOverlayService } from 'app/main/component/loader/dynamic-overlay.service';
import { LoaderComponent } from 'app/main/component/loader/loader.component';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';
import { BehaviorSubject } from "rxjs";
import { GanttOptions } from './gt-chart/gantt-options';

@Injectable({
  providedIn: 'root'
})
export class GanttChartService {

  apiUrl = environment.basePath;
  pageSize = 20;
  loaderOverlayRef: OverlayRef;
  private ganttOptionChanged = new BehaviorSubject<GanttOptions>(null);
  public ganttOption = this.ganttOptionChanged.asObservable();


  constructor(
    private baseService: BaseService,
    private dynamicOverlay: DynamicOverlayService,
    private overlay: Overlay,
  ) { }

  updateGanttOption(data) {
    this.ganttOptionChanged.next(data);
  }


  createProjectPlan(data) {
    return this.baseService.post(this.apiUrl + '/planProjects', true, data);
  }

  updateProjectPlan(data, id) {
    return this.baseService.patch(this.apiUrl + '/planProjects/' + id, true, data);
  }

  getAllProjectPlans(currentPage, planname) {
    let searchParams = {
      name: planname,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/planProjects/allPlanProject', true, searchParams);
  }

  getProjectPlanById(id) {
    return this.baseService.get(this.apiUrl + '/planProjects/' + id, true);
  }

  deleteProject(id) {
    return this.baseService.delete(this.apiUrl + '/planProjects/' + id, true);
  }

  showHideLoader(action, show, element: ElementRef) {
    if (show) {
      if (element)
        this.loaderOverlayRef = this.dynamicOverlay.createWithDefaultConfig(element.nativeElement, 'bgdrop2');
      else
        this.loaderOverlayRef = this.overlay.create({
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
          hasBackdrop: true,
          backdropClass: 'bgdrop'
        });
      const cref = this.loaderOverlayRef.attach(new ComponentPortal(LoaderComponent));
      cref.instance.action = action;
    }
    else {

      if (this.loaderOverlayRef) {
        this.loaderOverlayRef.detach();
      }
    }
  }

  getAllDivisions(currentPage, list) {
    let searchParams = {
      divisionName: list,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/divisionLists/divisions', true, searchParams);
  }

  addPlanList(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planList/add', true, data);
  }

  updatePlanList(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planList/updatePlanList', true, data);
  }

  deletePlanList(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planList/remove', true, data);
  }

  getAllCompanies(currentPage, searchterm, excludeSelf: boolean) {
    return this.baseService.get(this.apiUrl + `/companies/list?isExcludeSelf=${excludeSelf}&pageNumber=${currentPage}&pageSize=${this.pageSize}&companyName=${searchterm}`, true);
  }

  addTradePartnerProject(data) {
    return this.baseService.post(this.apiUrl + '/planProjects/createTradePartnership', true, data);
  }

  updateTradePartnerProject(data, id) {
    return this.baseService.patch(this.apiUrl + '/planProjects/updateTradePartnership/' + id, true, data);
  }

  getAllPlanCards(currentPage, card, divisionListId) {
    let searchParams = {
      divisionListId: divisionListId,
      cardName: card,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/divisionCards/cardsList', true, searchParams);
  }

  addPlanCard(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planCard/add', true, data);
  }

  updatePlanCard(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planCard/update_planCard_static', true, data);
  }

  deletePlanCard(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planCard/remove', true, data);
  }

  deleteTask(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planTask/remove', true, data);
  }

  getPlanCardsForDependency(projectid, pageNo, cardid) {
    return this.baseService.get(this.apiUrl + `/planProjects/${projectid}/cards?pageSize=${this.pageSize}&pageNumber=${pageNo}&planCardId=${cardid}`, true);
  }

  getPlanTaskForDependency(cardid, projectid, pageNo) {
    return this.baseService.get(this.apiUrl + `/planProjects/${projectid}/task/cards?pageSize=${this.pageSize}&pageNumber=${pageNo}&planCardId=${cardid}`, true);
  }

  addPlanCardDependency(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/cards/dependent-task', true, data);
  }

  addPlanTaskDependency(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/task/cards/dependent-task', true, data);
  }

  getDependentOnCards(cardid) { //parent cards
    return this.baseService.get(this.apiUrl + `/planProjects/planCards/${cardid}/dependent-task`, true);
  }

  getDependentOnTasks(cardid) { //parent tasks
    return this.baseService.get(this.apiUrl + `/planProjects/task/planCards/${cardid}/dependent-task`, true);
  }

  deletePlanCardDependency(cardid, depid) {
    return this.baseService.patch(this.apiUrl + `/planProjects/planCards/${cardid}/dependent-task/${depid}`, true, null);
  }

  deletePlanTaskDependency(cardid, depid) {
    return this.baseService.patch(this.apiUrl + `/planProjects/task/planCards/${cardid}/dependent-task/${depid}`, true, null);
  }

  freezePlan(planprojectid) {
    return this.baseService.post(this.apiUrl + `/planProjects/freeze-plan-project/${planprojectid}`, true, null);
  }

  addTask(data) {
    return this.baseService.patch(this.apiUrl + `/planProjects/planTask/add`, true, data);
  }

  updateTask(data) {
    return this.baseService.patch(this.apiUrl + `/planProjects/planTask/updatePlanTask`, true, data);
  }



}
